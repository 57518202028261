
<template>
    <div class="order-list-layout withTopBar fullbox">
        <van-nav-bar title="订单查询" left-text="返回" left-arrow border safe-area-inset-top fixed @click-left="onClickLeft"></van-nav-bar>
        <van-search v-model="keywords" placeholder="请输入手机号" left-icon="" right-icon="search" @search="handleSearch" />
        <div class="scroll-box" ref="scroll">
            <van-empty v-show="!loading && orderData.length==0" description="暂无数据" />
            <div class="item" v-for="(item,index) in orderData" :key="'order'+index">
                <div class="font15 pdb5">{{item.mobile}}</div>
                <div class="flex_box align_center pdb10">
                    <div class="flex_one font15 pdr10">{{item.address}}</div>
                    <div class="status" v-for="(status,i) in item.status_arr" :key="'status'+i" :style="statusStyle(status)">{{status.title}}</div>
                </div>
                <div class="flex_box">
                    <div class="product-img">
                        <!-- http://test.dingnai.cn/data/upload/2021/10/16355834737069.png!-->
                        <img class="" :src="item.product_pic" />
                    </div>
                    <div class="flex_one pdl10">
                        <div class="flex_box space_between">
                            <span class="font15 flex_one mb5">{{item.product_name}}</span>
                            <span class="pdl5 num">￥{{item.product_price}}</span>
                        </div>
                        <div class="flex_box space_between font12 color_gray_two mb5">
                            <span>配送模式：{{item.delivery_type}}</span>
                            <span class="pdl5 num">x{{item.num}}</span>
                        </div>
                        <div class="flex_box space_between mb5">
                            <div class="font14 color_gray_two">
                                配送日期：{{item.start_time}}至{{item.end_time}}
                            </div>
                            <div class="num pdl5">
                                x{{item.total_num}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex_box align_center space_end pdb10">
                    <div class="candler_btn mt5 mr10 border" @click="viewCalendar(item)">
                        订单日历
                    </div>
                    <div class="flex_box align_center font15">
                        <span class="">订单金额：</span>
                        <span class="money">￥{{item.total_money}}</span>
                    </div>
                </div>
                <van-divider />
                <div class="font14 color_gray">下单时间：{{item.add_time}} </div>
                <div class="font14 color_gray pdt5 pdb10" v-if="item.pay_time!==''">付款时间：{{item.pay_time}}（{{item.pay_type_str}}）</div>
                <div class="flex_box align_center space_end  pdb10" v-if="item.pay_status==0">
                    <div class="btn btn_primary" @click="pay(item)">去支付</div>
                </div>
                <div class="blank"></div>
            </div>
            <div class="loading" v-show="loading">
                <van-loading type="spinner" color="#1989fa" />
            </div>
        </div>

        <van-calendar v-model="showCalendar" type="range" title="订单日历" :min-date="firstDate" :default-date="selected" readonly :show-confirm="false" />
        <van-popup v-model="showCode" closeable :close-on-click-overlay="false" @closed="clearTimer">
          <div class="qrcode-box">
            <div class="text_center mb10 font16">请扫码支付</div>
            <div id="qrcode" ref="qrcode"></div>
            <div class="flex_box align_center mt10">
              <span class="mr10 font15">支付剩余时间：</span>
              <div class="">
                <van-count-down :time="time" @finish="finish">
                  <template #default="timeData">
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                  </template>
                </van-count-down>
              </div>
            </div>
          </div>
        </van-popup>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
    name:"pre-storage",
    data(){
        return{
            loading:false,
            orderData:[],
            keywords:"",
            isMore:true,
            page:1,
            rowCount:10,
            showCalendar:false,
            firstDate:new Date(),
            selected:[],
            showCode:false,
            payUrl:"",//支付链接weixin://wxpay/bizpayurl?pr=vZjjA5kzz
            time: 5*60*1000,
            timeData:{
                minutes:5,
                seconds:0
            },
            timer:null
        }
    },
    created(){
        this.index()
    },
    mounted(){
      this.$refs.scroll.addEventListener('scroll',this.handleScroll,true);
    },
    methods:{
        index(){
            const _this = this
            _this.loading = true
            _this.$api.common.getOrederList({
                mobile:_this.keywords,
                page:_this.page,
                rowCount:_this.rowCount
            }).then(res=>{
                _this.loading = false
                if(res.code =='0' && res.data){
                    let result = _this.orderData.concat(res.data.rows)
                    _this.orderData = result
                    _this.page++
                    if(res.data.rows.length < _this.rowCount){
                        _this.isMore = false
                    }
                }
            }).catch(() => {
                _this.loading = false;
            })
        },
        pay(order){//继续支付
            const _this = this
            _this.$api.common.continuePayOrder({
                id:order.id
            }).then(res=>{
                if(res.code =="0" && res.data){
                    _this.payUrl = res.data.code_url
                    _this.CheckPaySate(order.id)
                    _this.showCode = true
                    _this.$nextTick(() => {
                        _this.qrcode()
                    })
                }else{
                    _this.$toast.fail(res.msg)
                }
            })
        },
        handleScroll(){
          const _this = this

          const scrollTop = this.$refs.scroll.scrollTop
          // 获取可视区的高度
          const windowHeight = this.$refs.scroll.clientHeight
          // 获取滚动条的总高度
          const scrollHeight = this.$refs.scroll.scrollHeight
          if (scrollTop + windowHeight >= scrollHeight ) {
            // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
            if(_this.isMore && !_this.loading){
              _this.index()
            }
          }
        },
        viewCalendar(row){
            console.log(row)
            this.firstDate = new Date(row.start_time)
            this.selected.push(new Date(row.start_time))
            this.selected.push(new Date(row.end_time))
            this.showCalendar = true
        },
        finish(){
            const _this = this
            _this.$toast.fail('支付超时')
            _this.clearTimer()
            _this.showCode = false
        },
        qrcode() {
            const _this = this
            new QRCode("qrcode", {
                width: 300, // 二维码宽度，单位像素
                height: 300, // 二维码高度，单位像素
                text: _this.payUrl// 生成二维码的链接
            })
        },
        CheckPaySate(orderId){
            const _this = this
            this.timer=setInterval(()=>{
                _this.$api.common.getPayState({
                    order_id:orderId
                }).then(res=>{
                    if(res.code =="0" && res.data && res.data.pay_status == 1){
                        _this.clearTimer()
                        _this.showCode = false
                        _this.$toast.success('支付成功')
                        
                        _this.reload()
                    }
                })
            },5000)
        },
        handleSearch(){
            this.reload()
        },
        reload(){
            this.page = 1
            this.isMore = true
            this.orderData = []
            this.index()
        },
        statusStyle(obj){
            return 'color:'+obj.color+';background:'+obj.bg_color
        },
        clearTimer(){
            this.timer ? clearInterval(this.timer) : '' //关闭
        },
        onClickLeft(){
            this.$router.push({
                name: "home"
            })
        }
    },
    computed:{

    },
    beforeDestroy(){
       this.clearTimer()
    }
}
</script>

<style lang="scss">
@import "./order-list.scss"
</style>
